var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-container" },
    [
      _vm.leftText
        ? _c("span", { staticClass: "btn-container-text" }, [
            _vm._v(_vm._s(_vm.leftText)),
          ])
        : _vm._t("leftSide"),
      _c("btn", {
        staticClass: "btn-container-btn",
        class: _vm.btnClasses,
        attrs: {
          textSize: _vm.btnFontSize,
          type: _vm.type ? _vm.type : "play-dark-bg-black",
          hoverText: _vm.hoverText,
          text: _vm.text || _vm.rightText,
          selected: _vm.selected,
          altText: _vm.altText,
        },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }