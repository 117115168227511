<template>
    <div class="btn-container">
        <span v-if="leftText" class="btn-container-text">{{ leftText }}</span>
        <slot v-else name="leftSide"/>

        <btn
            :class="btnClasses"
            :textSize="btnFontSize"
            :type="type ? type : 'play-dark-bg-black'"
            :hoverText="hoverText"
            :text="text || rightText"
            :selected="selected"
            :altText="altText"
            class="btn-container-btn"
            @click="$emit('click')"
        />
    </div>
</template>

<script>
export default {
    name: "BtnTwice",
    props: {
        leftText: {type: String, default: ''},
        text: {type: String, default: ''},
        selected: {type: String, default: ''},
        rightText: {type: String, default: ''},
        hoverText: {type: String, default: ''},
        type: {type: String, default: ''},
        btnFontSize: {type: Number, default: 15},
        btnClasses: {type: String, default: ''},
        altText: {type: String, default: ''},
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.btn-container {
    display: flex;

    &-text {
        font: 500 22px "Tektur";
        padding: 0 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: none;
        color: black;
        background: linear-gradient(45deg, #FF9509 0%, #FFCC13 100%);
        cursor: default;
    }

    &-btn {
        margin: 0;
        font: 500 18px "Tektur";
    }
}
</style>
